<template>
    <div>
        <div>
            <h2 class="th-title">Rollout</h2>
            <!-- <h2 class="th-title">Monitoring Tanggal {{ getHumanDate(monitoringDate) }}</h2> -->
            <!-- <a>
          <p class="text-muted mb-5" @click="showModalDate"> Ganti tanggal monitoring</p>
        </a> -->
            <!-- <a-modal v-model:visible="visibleDate" title="Basic Modal" @ok="handleDate">
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </a-modal> -->
        </div>
        <a-card>
            <div class="row mb-3">
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2 ">
                    <a-input-search placeholder="Cari Nama Aset..." v-model:value="search" />
                </div>
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-select v-model:value="selectedFilterStatus" class="w-100">
                        <a-select-option value="Filter Tipe Asset">Filter Tipe Asset</a-select-option>
                        <a-select-option value="Active Devices">Active Devices</a-select-option>
                        <a-select-option value="Passive Devices">Passive Devices</a-select-option>
                        <a-select-option value="Facilities">Facilities</a-select-option>
                        <a-select-option value="Software">Software</a-select-option>
                        <a-select-option value="Infrastructure">Infrastructure</a-select-option>
                    </a-select>
                </div>
                <!-- <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-select v-model:value="selectedFilterStatus" class="w-100">
                        <a-select-option value="Filter Tipe Asset">Filter Per Tahun</a-select-option>
                        <a-select-option value="Active Devices">2020</a-select-option>
                        <a-select-option value="Passive Devices">2021</a-select-option>
                        <a-select-option value="Facilities">2022</a-select-option>
                        <a-select-option value="Software">2023</a-select-option>
                        <a-select-option value="Infrastructure">2024</a-select-option>
                    </a-select>
                </div> -->
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-month-picker v-model:value="selectedMonthStatus" placeholder="Filter Per Bulan" />
                </div>
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-button @click="clearFilter">
                        <UndoOutlined />Clear Filter
                    </a-button>
                </div>
                <div class="col-6 my-2 my-xl-0 col-md-3 col-xl-2">
                    <a-button @click="exportToExcel">
                        <ExportOutlined />Export XLS
                    </a-button>
                </div>
            </div>
            <div style="overflow-x:auto ;">
                <a-table :columns="role === 'dcOwner' ? columns : columns2" :data-source="budgetData" bordered>
                </a-table>
            </div>
        </a-card>
    </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw, watch, defineComponent, h } from 'vue';
import * as XLSX from 'xlsx';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, WarningFilled, ExportOutlined, UndoOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
import moment from 'moment'
import {
    generateTask, getTaskByDate,
} from '../../services/axios/modc/eos'
import {
    getVendors,
} from '../../services/axios/modc/vendor'
import {
    getBudgetPlan,
} from '../../services/axios/modc/ats'
import rupiahPrice from '../../helpers/rupiahPrice.js'
import lodash from 'lodash'
export default defineComponent({
    components: {
        ExportOutlined, UndoOutlined,
    },
    setup() {
        const columns2 = [
            {
                title: 'No',
                dataIndex: 'key',
                key: 'key',
                align: 'center',
            },
            {
                title: 'Nama Asset',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
            },
            {
                title: 'Tipe Asset Kategori',
                dataIndex: 'AssetSubCategory.AssetCategory.name',
                key: 'asset',
                align: 'center',
                // slots: { customRender: 'name' },
            },
            {
                title: 'Tipe Asset Sub Kategori',
                dataIndex: 'AssetSubCategory.name',
                key: 'asset',
                align: 'center',
                // slots: { customRender: 'name' },
            },
        ];
        const columns = [
            {
                title: 'No',
                dataIndex: 'key',
                key: 'key',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {

                                },
                                "Total :",
                            ),
                            props: {
                                colSpan: 4,
                                align: 'center',
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        text,
                    );
                },
            },
            {
                title: 'Nama Asset',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                rupiahPrice(totalValue.value.totalAssetValue),
                            ),
                            props: {
                                colSpan: 1,
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        text,
                    );
                },
            },
            {
                title: 'Tipe Asset',
                dataIndex: 'AssetSubCategory.AssetCategory.name',
                key: 'asset',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                rupiahPrice(totalValue.value.totalEstimatedAssetValue),
                            ),
                            props: {
                                colSpan: 1,
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        text,
                    );
                },
                // slots: { customRender: 'name' },
            },
            {
                title: 'Tanggal Pembelian',
                dataIndex: 'ProcurementInformation.purchaseDate',
                key: 'category',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                text,
                            ),
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        moment(text).format('DD-MM-YYYY'),
                    );
                },
                // slots: { customRender: 'description' },
            },
            {
                title: 'Harga Asset (IDR.)',
                dataIndex: 'value',
                key: 'procurement',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                text,
                            ),
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        rupiahPrice(text),
                    );
                },
            },
            {
                title: 'Estimasi Harga Asset  (IDR.)',
                dataIndex: 'estimatedValue',
                key: 'maintenance',
                align: 'center',
                customRender: ({ text, index }) => {
                    if (index === budgetData.value.length - 1) {
                        return {
                            children: h(
                                'p',
                                {
                                    href: 'javascript:;',
                                },
                                text,
                            ),
                            props: {
                                colSpan: 0,
                            },
                        };
                    }
                    return h(
                        'p',
                        {
                            href: 'javascript:;',
                        },
                        rupiahPrice(text),
                    );
                },
            },
            // {
            //     title: 'Keterangan',
            //     key: 'ceklis',
            //     slots: { customRender: 'action' },
            //     align: 'center',
            // },
        ];
        const store = useStore()
        const role = ref()
        let budgetData = ref([])
        let realBudgetData = ref([])
        // let search = ref(null)
        const totalValue = ref()

        onMounted(() => {
            fetchBudgetPlan()
        })

        const fetchBudgetPlan = async (search = '') => {
            const { data, total } = await getBudgetPlan()
            const storedUserData = localStorage.getItem('userData')
            const userData = JSON.parse(storedUserData)
            role.value = userData.user.role
            data.forEach((e, idx) => {
                e.key = idx + 1
            })
            //ditambah data kosong buat dapet 1 row yang bisa di custom total
            const totalRow = { key: 'total-row' }
            if (role.value === 'dcOwner') {
                data.push(totalRow)
            }
            budgetData.value = data
            realBudgetData.value = data
            totalValue.value = total
        }
        const exportToExcel = () => {
            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Define the header based on the columns
            let headers = []

            if (role.value === 'dcOwner') {
                headers = ['No', 'Nama Asset', 'Tipe Asset', 'Tanggal Pembelian', 'Harga Asset (IDR.)', 'Estimasi Harga Asset (IDR.)'];
            } else {
                headers = ['No', 'Nama Asset', 'Tipe Asset Kategori', 'Tipe Asset Sub Kategori'];
            }

            // Create an array of arrays from the data
            let data = []
            if (role.value === 'dcOwner') {
                data = budgetData.value.map((item, index) => [
                    item.key,
                    item.name,
                    item?.AssetSubCategory?.AssetCategory?.name,
                    moment(item?.ProcurementInformation?.purchaseDate).format('DD-MM-YYYY'),
                    item?.value,
                    item?.estimatedValue,
                ]);
                data.pop()
                // Add the total row at the end
                data.push(['', '', '', '', '', ''], [
                    '', // Empty cell for 'Garansi Berakhir'
                    '', // Empty cell for 'Harga Pengadaan (IDR.)'
                    '', // Empty cell for 'Harga Maintenance (IDR.)'
                    'Total:',
                    `IDR.${totalValue.value.totalAssetValue ? totalValue.value.totalAssetValue : 0}`,
                    `IDR.${totalValue.value.totalEstimatedAssetValue ? totalValue.value.totalEstimatedAssetValue : 0}`,
                ]);
            } else {
                data = budgetData.value.map((item, index) => [
                    item.key,
                    item.name,
                    item?.AssetSubCategory?.AssetCategory?.name,
                    item?.AssetSubCategory?.name,
                ]);
            }

            // Convert the array of arrays to a worksheet
            const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

            // Set column widths
            worksheet['!cols'] = [
                { wch: 5 }, // No
                { wch: 50 }, // Nama Asset
                { wch: 25 }, // Tipe Asset
                { wch: 30 }, // Garansi Berakhir
                { wch: 30 }, // Harga Pengadaan (IDR.)
                { wch: 30 }, // Harga Maintenance (IDR.)
            ];

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Budget Plan');

            // Generate a binary string (can be changed to various formats e.g., binary string, base64, etc.)
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

            // Create a Blob from the binary string
            let blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

            // Create a link element and trigger the download
            let url = URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'MODC_Budget_Plan_Rollout.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        };

        const s2ab = (s) => {
            const buffer = new ArrayBuffer(s.length);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < s.length; i++) {
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buffer;
        };
        let search = ref('')
        let selectedFilterStatus = ref('Filter Tipe Asset')

        // const lodashSearch = lodash.debounce(() => fetchBudgetPlan(search.value), 1500)
        // const handleSearch = () => {
        //     // globalPagination.value.current = 1
        //     lodashSearch()
        //     // fetchCourse({ search: search.value })
        // }
        let selectedMonthStatus = ref()
        const clearFilter = () => {
            search.value = ''; selectedFilterStatus.value = 'Filter Tipe Asset'; selectedMonthStatus.value = ''; selectedFilterStatus.value = 'Filter Tipe Asset';
        }
        watch([search, selectedFilterStatus, selectedMonthStatus], (to, from) => {
            // console.log(search.value)
            // console.log(realBudgetData.value[0])
            // realBudgetData.value.forEach((e) => { console.log(e.name, '===', search.value) })
            // budgetData.value = realBudgetData.value.map(e => {
            //     if (e?.name.includes(search.value)) {
            //         return e
            //     }
            // });
            budgetData.value = realBudgetData.value
            if (search.value) {
                budgetData.value = realBudgetData.value.filter(e =>
                    e.name && e.name.includes(search.value),
                );
            }

            if (selectedFilterStatus.value !== 'Filter Tipe Asset') {
                budgetData.value = realBudgetData.value.filter(e =>
                    e?.AssetSubCategory && e?.AssetSubCategory.AssetCategory.name === selectedFilterStatus.value,
                );
            }
            if (selectedMonthStatus.value) {
                console.log(moment(selectedMonthStatus.value).format('YYYY'), 'inifiltermonts')
                budgetData.value = realBudgetData.value.filter((e) =>
                    e?.ProcurementInformation?.purchaseDate && moment(e?.ProcurementInformation?.purchaseDate).format('YYYY-MM') === moment(selectedMonthStatus.value).format('YYYY-MM'),
                );
                if (budgetData.value.length) {
                    budgetData.value.push({ key: 'total-row' })
                }
            }
        })



        return {
            budgetData,
            totalValue,
            columns,
            columns2,
            search,
            role,
            exportToExcel,
            s2ab,
            selectedFilterStatus,
            clearFilter,
            selectedMonthStatus,
        }
    },

    methods: {
        getHumanDate: function (date) {
            return moment(date).format('DD MMM YYYY')
        },
    },
})


</script>
